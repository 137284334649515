/*
**
**  Messagebox.scss
**
**  for usage choose one option:
**  <div class="messagebox"><h3> ...
**  <div class="messagebox messagebox--error"><h3> ...
**  <div class="messagebox messagebox--success"><h3> ...
**
*/

.messagebox {

  position: relative;
  padding: 20px 20px 20px 60px;
  margin-bottom: 20px;
  border: 3px solid $color-message;

  &::before {
    position: absolute;
    top: 13px;
    left: 14px;
    display: block;
    width: 32px;
    height: 32px;
    content: "";
    background-image: url("../../images/icons/message/info.svg");
  }

  &--error {
    border-color: $color-error;
    &::before {
      background-image: url("../../images/icons/message/error.svg");
    }
  }

  &--success {
    border-color: $color-success;
    &::before {
      background-image: url("../../images/icons/message/success.svg");
    }
  }

  h3 {
    margin: -2px 0 6px;
  }

}
