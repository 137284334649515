/*
**
** signin-layout-main.scss: elementary layout of main page (50/50 layout)
**
*/


html,
body {
  height: 100%;
}

.signin-basecontainer {
  height: 100%;
}

.signin-container {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.signin-primarySection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.signin-secondarySection {
  width: 50%;

  .navigation-ui-footer {
    display: none;
  }
}


.password-area {
  position: relative;

  button {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 9;
    width: 25px;
    height: 25px;
    cursor: pointer;

    &.hidden-password {
      background-image: url("../../images/icons/eye_off.svg");
    }

    &.show-password {
      background-image: url("../../images/icons/eye_on.svg");
    }
  }
}

/* -------------------------------------- media qu. -------------------------------------- */


@media (max-width: 980px) {
  .signin-container {
    flex-direction: column;
    height: auto;
    padding-bottom: 16px;
  }

  .signin-primarySection,
  .signin-secondarySection {
    width: auto;
  }

  .signin-primarySection {

    padding-bottom: 50px;

    .navigation-ui-footer {
      display: none;
    }
  }

  .signin-secondarySection {

    .navigation-ui-footer {
      display: block;
    }
  }

}


@media (max-width: 640px) {

  .signin-primarySection {

    padding-bottom: 30px;

  }

}
