/*
**
** Headline.scss
**
*/

h1,
h2,
h3,
h4 {
  margin: .2em 0 .625em;
  line-height: 1.4;
  color: $color-gray-20;
  word-wrap: break-word;
}

h1 a,
h2 a,
h3 a,
h4 a {
  text-decoration: none;
}

h1,
.like_h1 {
  font-size: 2.444em;
  font-weight: 300;
}

h2,
.like_h2 {
  font-size: 2.055em;
  font-weight: 300;
}

h3,
.like_h3 {
  font-size: 1.5em;
  font-weight: 400;
}

h4,
.like_h4 {
  font-size: 1.25em;
  font-weight: 400;
}
