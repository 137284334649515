body {
  div.usercentrics-button {
    height: 0;
  }
}

/**
 ** Overwrites the inline style set by the consent layer.
 */
html {
  height: 100% !important; /* stylelint-disable-line declaration-no-important */
}

