/*
**
** colors.scss: color vars non platform specific
**
*/

/* ---------------------------------------------------------------------------- *\
    gray scale
\* ---------------------------------------------------------------------------- */

$color-black: #000 !default; // zusätzlich: #000000 und black
$color-gray-9: #171717 !default;
$color-gray-20: #333 !default; // zusätzlich: #333333
$color-gray-31: #4f4f4f !default; // zusätzlich: #505050
$color-gray-40: #666 !default;
$color-gray-47: #787878 !default; // zusätzlich: #777777 und #777
$color-gray-51: #828282 !default; // und #818181
$color-gray-60: #999 !default; // zusätzlich: #999999
$color-gray-67: #ababab !default; // zusätzlich: #aaaaaa und #aaa
$color-gray-73: #bababa !default; // ausschl. #bbbbbb und #bbb
$color-gray-75: #bfbfbf !default; // und #c0c0c0
$color-gray-80: #ccc !default; // zusätzlich: #cccccc und  #cdcdcd
$color-gray-85: #d9d9d9 !default; // und #dadada und #d8d8d8
$color-gray-87: #dedede !default; // und #ddd
$color-gray-90: #e5e5e5 !default; // zusätzlich e6e6e6
$color-gray-93: #ededed !default; // zusätzlich: #eeeeee und #eee
$color-gray-94: #f0f0f0 !default; // und #efefef
$color-gray-95: #f2f2f2 !default; // und #f1f1f1
$color-white: #fff !default; // zusätzlich: #ffffff und white und rgba(255,255,255,1)

/* ---------------------------------------------------------------------------- *\
    colors for information, error colors etc.
\* ---------------------------------------------------------------------------- */

$color-message: #ffd941 !default;
$color-success: #7cd07a !default;
$color-error: #ff5a54 !default;
$color-error-light: #fcc !default;
$color-important-hyperlink: #e65100 !default;
$color-yellow-brown-light: #ffd942 !default;
$color-orange-message: #ffd942 !default;
$color-button-primary: #333 !default;
