/*
**
** styles for IN only
**
*/

.signin-link::before {
  display: block;
  width: 12px;
  height: 12px;
  padding-right: 15px;
  content: "";
  background-image: url("../../images/icons/icons-in/arrow-right.svg");
  background-repeat: no-repeat;
}
