/*
**
** signin-layout-app.scss: additional app layout of main page (50/50 layout)
**
*/
.signin-infoWrap {
  display: none;
}

.signin-altImprintSection {
  display: none;
}

.signin-remember-me-inputfield {
  display: none;
}

@media (min-width: 981px) {
  .signin-secondarySection {
    width: 0%;
  }

  .signin-primarySection {
    width: 100%;
  }
}
