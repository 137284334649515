
/*
**
** signin-layout-aux-pages.scss: styles for redirect page et al
**
*/

.signin-aux-page {
  overflow: hidden;
  body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    > div {
      flex-grow: 2;
      padding-bottom: 30px;
      overflow-y: auto;
    }
  }
  .iw_content {
    max-width: 1200px;
    padding: 0 30px;
    margin: 30px auto 0;
  }

  // redirect page
  .iw_content--redir {
    margin-top: 80px;

    h2 {
      font-size: 18px;
    }
  }
}

@media (max-width: 980px) {

  .signin-aux-page {
    // redirect page
    .iw_content--redir {
      margin-top: 40px;
    }
  }

}

@media (max-width: 380px) {

  .signin-aux-page {
    h1 {
      font-size: 26px;
    }
  }

}
