
/*
**
** reset.scss
**
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ul,
ol,
li,
fieldset,
form,
label,
legend,
input,
select,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", arial, helvetica, "droid sans", sans-serif;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  vertical-align: baseline;
  background-color: transparent;
  border: 0;
  /* stylelint-disable */
  outline: 0;
  /* stylelint-enable */
}

body {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: $color-gray-20;
  background: $color-white;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


a {
  color: $color-gray-20;
}

a:hover {
  color: $color-gray-20;
  text-decoration: none;
}
