/*
**
** signin-layout-info.scss: styles for second section with several teaser and information
**
*/

.signin-infoWrap {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  background-color: $color-gray-93;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  /* large visuals */
  @media (min-width: 981px) {
    background-image: url("../../images/teaser/login-default-lgm.jpg");

    &.signin-infoWrap--applicantportfolio {
      background-image: url("../../images/teaser/login-user-lgm.jpg");
    }

    &.signin-infoWrap--objectmemo {
      background-image: url("../../images/teaser/login-memo-lgm.jpg");
    }

    &.signin-infoWrap--eigentuemerwelt {
      background-image: url("../../images/teaser/login-ownerworld-lgm.jpg");
    }

    &.signin-infoWrap--mortgageenquiry {
      background-image: url("../../images/teaser/login-mortgageenquiry-lgm.jpg");
    }
  }

  /* small visuals */
  @media (max-width: 980px) {
    background-image: url("../../images/teaser/login-default-small.jpg");

    &.signin-infoWrap--applicantportfolio {
      background-image: url("../../images/teaser/login-user-small.jpg");

      // individual setting: height of white blurry / softening
      .signin-infoBlur {
        background: linear-gradient(0deg, rgba(255, 255, 255, .82) 71%, rgba(255, 255, 255, 0) 100%);
      }

    }

    &.signin-infoWrap--objectmemo {
      background-image: url("../../images/teaser/login-memo-small.jpg");

      // individual setting: height of white blurry / softening
      .signin-infoBlur {
        background: linear-gradient(0deg, rgba(255, 255, 255, .87) 70%, rgba(255, 255, 255, 0) 100%);
      }

    }

    &.signin-infoWrap--eigentuemerwelt {
      background-image: url("../../images/teaser/login-ownerworld-small.jpg");

      // individual setting: height of white blurry / softening
      .signin-infoBlur {
        background: linear-gradient(0deg, rgba(255, 255, 255, .87) 70%, rgba(255, 255, 255, 0) 100%);
      }

    }

    &.signin-infoWrap--mortgageenquiry {
      background-image: url("../../images/teaser/login-mortgageenquiry-small.jpg");

      // individual setting: height of white blurry / softening
      .signin-infoBlur {
        background: linear-gradient(0deg, rgba(255, 255, 255, .87) 70%, rgba(255, 255, 255, 0) 100%);
      }

    }
  }

  .signin-infoContentWrap {
    position: relative;
    width: 100%;
  }

  .signin-infoBlur {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    // default setting
    background: linear-gradient(0deg, rgba(255, 255, 255, .79) 50%, rgba(255, 255, 255, 0) 100%);
  }

  /* styling for content elements like header, copy etc. */

  .signin-infoContent {
    position: relative;
    z-index: 25;
    padding: 120px 144px 58px;
  }

  .signin-headerWrap {
    margin-bottom: 40px;

    h1 {
      max-width: 900px;
      font-size: 28px;
      color: inherit;
    }

    h3 {
      max-width: 900px;
      font-size: 16px;
      font-weight: 400;
      color: inherit;
    }

    b,
    .bold {
      font-weight: 600;
    }
  }

  .signin-benefitWrap {
    display: flex;
    flex-wrap: wrap;

    .signin-benefit {
      display: flex;
      width: 50%;
    }

    .signin-benefitText {
      display: flex;
      min-height: 40px;
      margin: 0 40px 40px 0;
      line-height: 22px;

      div {
        display: flex;
        align-items: center;
      }
    }

    .signin-benefitIconWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-top: 3px;
      margin-right: 16px;
      margin-bottom: 3px;
      font-size: 24px;
      background-color: $color-white;
      border-radius: 13px;

      img {
        width: 100%;
      }

    }
  }
}

/* -------------------------------------- media qu. -------------------------------------- */

@media (max-width: 2000px) {

  .signin-infoWrap {
    .signin-infoContent {
      padding: 100px 10% 35px;
    }
  }

}

@media (max-width: 1500px) {

  .signin-infoWrap {

    .signin-infoContent {
      padding: 15vw 10% 18px;
    }

    .signin-benefitWrap {
      flex-direction: column;
      flex-wrap: nowrap;

      .signin-benefit {
        width: 100%;
      }
    }
  }

}

@media (max-width: 1100px) {

  .signin-infoWrap {

    .signin-benefitWrap {
      .signin-benefitText {
        margin: 0 40px 32px 0;
      }
    }
  }

}

@media (max-width: 980px) {

  .signin-infoWrap {
    padding-top: 33%;
    background-position: center -30px;
  }

}

@media (max-width: 640px) {

  .signin-infoWrap {
    .signin-infoContent {
      padding-top: 18vw;
      padding-right: 20px;
      padding-left: 20px;

      h1 {
        margin-bottom: 24px;
      }
    }
  }

}

@media (max-width: 420px) {

  .signin-infoWrap {
    .signin-benefitWrap {
      .signin-benefit {
        display: block;
        margin-bottom: 16px;
      }

      .signin-benefitIconWrap {
        float: left;
      }

      .signin-benefitText {
        display: block;
        margin: 0;
        margin-bottom: 4px;
      }
    }
  }

}
