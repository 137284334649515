/*
**
** signin-layout-navigation-ui.scss: behaviour and styling of header and footer
**
*/

.signin-mainpage {

  /*
  **
  **
  **  1. header
  **  placeholder for navigation-ui-header should be in the template, reasons (08/2019):
  **  a) pandora wants header and footer to be always used together b) css comes with header - without header no css
  **
  **
  */

  /* stylelint-disable selector-type-no-unknown */
  navigation-ui-header {
    height: 0;
    visibility: hidden;

    div {
      display: none;
    }
  }

  /* stylelint-enable */

  .navigation-ui-header {
    display: none;
    height: 0;
    visibility: hidden;
  }

  /*
  **
  **
  **  2. footer
  **  OVERRIDES ('custom' styles from tarvos)
  **
  **
  */

  .signin-container {
    .navigation-ui-footer {
      color: $color-gray-20; // for in
      background-color: $color-white; // for in
      border-top: 0 none; // for iw
      a,
      a:hover {
        color: inherit; // for in
      }
    }
  }


  @media (max-width: 980px) {

    .signin-container {
      .navigation-ui-footer {
        .navigation-ui-content-wrapper {
          padding: 0 100px;
        }
      }
    }

  }
}
