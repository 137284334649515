/*
**
** Link.scss
**
*/

.signin-link {
  display: flex;
  align-items: baseline;
  color: $color-link;
  text-decoration: none;

  &::before {
    display: block;
    width: 12px;
    height: 12px;
    padding-right: 15px;
    content: "";
    background-image: url("../../images/icons/arrow-right.svg");
  }

  &:hover {
    color: $color-link-hover;
    text-decoration: none;
  }

  &:hover::before {
    filter: saturate(450%);
  }

}
