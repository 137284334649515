/*
**
** Button.scss:
**
*/

.signin-button {
  display: block;
  height: 38px;
  padding: 6px 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  transition: background-color .3s ease 0s, color .3s ease 0s;
  transition-delay: 0s, 0s;
  transition-timing-function: ease, ease;
  transition-duration: .3s, .3s;
  transition-property: background-color, color;
  /* stylelint-disable-next-line property-no-vendor-prefix, plugin/no-unsupported-browser-features */
  -webkit-appearance: none; // for iPhone

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &.signin-button--big {
    height: 40px;
    padding: 8px 14px;
    font-size: 18px;
    line-height: 24px;
  }

  &.signin-button--primary {
    color: $color-font-button;
    background: $color-bg-primary-button;
    &:hover {
      background-color: $color-bg-primary-button-hover;
    }
  }

  &.signin-button--black{
    line-height: 1.5;
    background: transparent;
    border: 1px solid $color-gray-9;

    &:hover {
      color: $color-white;
      background-color: $color-black;
    }

  }


}

@media (max-width: 640px) {

  .signin-button {
    width: 100%;
  }

}
