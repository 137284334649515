/*
**
** colors-in.scss: color vars for IN
**
*/

/* ---------------------------------------------------------------------------- *\
    - identity colors
\* ---------------------------------------------------------------------------- */

$color-primary: #009acf !default;
$color-bg-primary-button: #a4be04 !default;
$color-bg-primary-button-hover: #8ea503 !default;
$color-font-button: #fff !default;
$color-link: #008fc4 !default;
$color-link-hover: #005b8f !default;

