input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="number"],
input[type="password"] {
  width: 100%;
  height: 36px;
  padding: 6px 12px;
  margin-bottom: 10px;
  line-height: 22px;
  cursor: auto;
  background: $color-white;
  border: 1px solid $color-gray-80;
  border-radius: 3px;
  transition: background 150ms ease-out 0s;

  &.error {
    margin-bottom: 1px;
    background-color: $color-error-light;

    + span {
      display: block;
      margin-bottom: 10px;
      color: $color-error;
    }
  }

}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
input[type="password"]:focus {
  color: $color-gray-20;
  border: 1px solid $color-primary;
}
