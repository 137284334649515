/*
**
** signin-layout-form.scss: styles for main section with form
**
*/

.signin-formOuterWrap {

  width: 55%;
  max-width: 470px;
  margin: 0 auto;

  .signin-logoWrap {
    display: block;

    height: 30px;
    margin-top: 1.5vw;
    margin-bottom: 4.2vw;

    img,
    picture {
      height: 100%;
    }
  }

  .signin-policy,
  .signin-mtan {
    padding-bottom: 20px;
    font-size: 12px;
  }

  .signin-forgot {
    margin-bottom: 25px;
    font-weight: 700;
  }

  .signin-trust-browser {
    margin-bottom: 25px;
  }

  .signin-privacy {
    display: flex;
    align-items: baseline;
    margin-bottom: 25px;
    color: $color-link;
    text-decoration: none;

    &::before {
      display: block;
      width: 12px;
      height: 12px;
      padding-right: 15px;
      content: "";
      background-image: url("../../images/icons/lock.svg");
      background-size: 14px;
    }
  }

  .signin-button--primary {
    width: 100%;
    margin-bottom: 25px;
  }

  .signin-additional-login-fields {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .signin-formSwitch {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

  }

  .hidden {
    display: none;
  }
}


/* -------------------------------------- media qu. -------------------------------------- */

@media (max-width: 1500px) {

  .signin-formOuterWrap {
    width: 70%;

    .signin-logoWrap {
      margin-top: 2.5vw;
    }
  }

}

@media (max-width: 1250px) {

  .signin-formOuterWrap {
    width: 80%;
  }

}

@media (max-width: 1100px) {

  .signin-formOuterWrap {
    width: 90%;
  }

}

@media (max-width: 980px) {

  .signin-formOuterWrap {
    width: 100%;
    max-width: none;
  }

  .signin-formInnerWrap,
  .signin-logoWrap {
    width: 70%;
    max-width: 500px;
    margin: 0 auto;
  }

}

@media (max-width: 640px) {

  .signin-formOuterWrap {
    .signin-formInnerWrap,
    .signin-logoWrap {
      width: 100%;
      max-width: none;
      padding: 0 20px;
    }

    .signin-formInnerWrap {
      h1 {
        margin-bottom: 8px;
        font-size: 25px;
      }

      .signin-button--primary {
        margin-bottom: 16px;
      }
    }

    .signin-logoWrap {
      margin-top: 2.8vw;
    }

    .signin-formSwitch {
      flex-direction: column;

      // for ie
      :first-child {
        align-self: flex-start;
      }
    }
  }

}

@media (max-width: 500px) {

  .signin-formOuterWrap {
    .signin-formInnerWrap {

      .signin-additional-login-fields {
        flex-direction: row;

        .remember-me-inputfield {
          margin-bottom: 8px;
        }
      }

      .signin-logoWrap {
        text-align: center;
      }

      .signin-button--primary {
        margin-bottom: 24px;
      }

      .signin-formSwitch {
        :first-child {
          display: none;
        }
      }

    }
  }
}


@media (max-width: 350px) {

  .shorten {
    display: none;
  }

}

