/*
**
** Label.scss
**
*/

label {
  display: block;
  padding-bottom: 4px;
}
